class Course {
  constructor(
    vidTitle,
    vidSubtitle,
    presentation,
    presentationVid,
    story,
    downloadPresentation,
    tabs
  ) {
    this.vidTitle = vidTitle;
    this.vidSubtitle = vidSubtitle;
    this.presentation = presentation;
    this.presentationVid = presentationVid;
    this.story = story;
    this.downloadPresentation = downloadPresentation;
    this.tabs = tabs;
  }
}
class Tab {
  constructor(head, video, downloadLink) {
    this.head = head;
    this.video = video;
    this.downloadLink = downloadLink;
  }
}
const courses = [
  new Course(
    "Case #1: Dr Noel Amaladoss",
    "Optimizing Treatment Outcomes in Insomnia",
    "Watch Donna's Story",
    "https://vimeo.com/811288473/d38b9c1b02",
    "Optimizing Treatment Outcomes in Insomnia",
    "https://player.vimeo.com/progressive_redirect/download/811288473/rendition/2160p/donna%27s_story%20%282160p%29.mp4?loc=external&signature=288f61a8b3fb8782e66757e5ec55810f6103836bc7b5a331dea057ca31003231", [
    new Tab(
      "Disease and Case Overview",
      "https://vimeo.com/811287726/8ef556b4bc",
      "https://player.vimeo.com/progressive_redirect/download/811287726/rendition/1080p/dr_amaladoss_part_1.mp4%20%281080p%29.mp4?loc=external&signature=e310fc8ebd04f31c24b12f65d6c7288a0576a72172278f39ab38d0798b2b8e1c"),
    new Tab(
      "Treatment Decision",
      "https://vimeo.com/811287822/12f7557bcc",
      "https://player.vimeo.com/progressive_redirect/download/811287822/rendition/1080p/dr_amaladoss_part_2%20%281080p%29.mp4?loc=external&signature=be3a0b743e06a749836dd50c7b3e2cb87c573eb2ff87ee2d5b3deafd6118ea8c"),
    new Tab(
      "Case Summary",
      "https://vimeo.com/811287856/400f0d92c8",
      "https://player.vimeo.com/progressive_redirect/download/811287856/rendition/1080p/dr_amaladoss_part_3%20%281080p%29.mp4?loc=external&signature=fb5506bf099bf827d73a87ba03d5871a027ded46b1bbe25d913b6d054d2ac962"),
  ]
  ),
  new Course(
    "Case #2: Dr Daniel Ngui",
    "Multimorbidity, Polypharmacy and the Stressors of Life",
    "Watch Arvind's Story",
    "https://vimeo.com/811280081/249bbc91de",
    "Multimorbidity, Polypharmacy and the Stressors of Life",
    "https://player.vimeo.com/progressive_redirect/download/811280081/rendition/1080p/arvinds_story%20%281080p%29.mp4?loc=external&signature=0a2c9454be11a6122e8db45b2ccdff3f0139a0437cf06437a18225c72281d735", [
    new Tab(
      "Disease and Case Overview",
      "https://vimeo.com/811279936/27a623068d",
      "https://player.vimeo.com/progressive_redirect/download/811279936/rendition/1080p/dr_ngui_test_part_1%20%281080p%29.mp4?loc=external&signature=398ae2890f6c0a22ce9d6ba5205c02d73457d0078de0b787034bb0ced6261589"),
    new Tab(
      "Treatment Decision",
      "https://vimeo.com/811280043/778eb78aca",
      "https://player.vimeo.com/progressive_redirect/download/811280043/rendition/1080p/dr_ngui_test_part_2%20%281080p%29.mp4?loc=external&signature=d0b1295e786908bf671af6c27c3264351ca79617eedbc963132378e0eda955d5"),
    new Tab(
      "Case Summary",
      "https://vimeo.com/818411889/fa423553df",
      "https://player.vimeo.com/progressive_redirect/download/818411889/rendition/1080p/dr_ngui_part_3%20%281080p%29.mp4?loc=external&signature=b42395854a3b9da908ed6b1e111afb8a8a8e2a1e5bc737a34a2073a368a2efea"),
  ]
  ),
  new Course(
    'Case #3: Dre Sarah Landry',
    "Vignette Clinique - Madame Patricia",
    "L'histoire de Patricia",
    "https://vimeo.com/828134645/286001e097",
    "L'histoire de Patricia",
    "https://player.vimeo.com/progressive_redirect/download/828134645/rendition/1080p/patricia_story%20%281080p%29.mp4?loc=external&signature=ee21f42d31343f979eb2f916882023aaf97b1d6d4fed39b8279011ea214b5496",
    [
      new Tab(
        "Seg 1 - Introduction et aperçu du cas",
        "https://vimeo.com/827428090/114adb89d5",
        "https://player.vimeo.com/progressive_redirect/download/827428090/rendition/1080p/dr_landry_part_1%20%281080p%29.mp4?loc=external&signature=822477c84f3a8e85a24e9cf351a4e55e0b76dca2bec1b73f7b29e874c36022cb"
      ),
      new Tab(
        "Seg 2 - Décisions de traitement initiales",
        "https://vimeo.com/828196581/21a93da208",
        "https://player.vimeo.com/progressive_redirect/download/828196581/rendition/1080p/dr_landry_part_2%20%281080p%29.mp4?loc=external&signature=db699757ddd120d53b2415c2777e9354e5c32c2ee40255425aa3f29176174728"
      ),
      new Tab(
        "Seg 3 - Insomnie persistante!",
        "https://vimeo.com/828201665/a1686beb46",
        "https://player.vimeo.com/progressive_redirect/download/828201665/rendition/1080p/dr_landry_part_3%20%281080p%29.mp4?loc=external&signature=d779abb452513f5b40c1e067ad8603be3f9b14a9b3fc34015e4f376450087ce9"
      ),
      new Tab(
        "Seg 4 - Choix finaux et conclusion",
        "https://vimeo.com/828201699/9d7d64d249",
        "https://player.vimeo.com/progressive_redirect/download/828201699/rendition/1080p/dr_landry_part_4%20%281080p%29.mp4?loc=external&signature=cf54b08eb3f6608f209bdf579b25427c828becc380f13e47c5527d0438634bb5"
      ),
    ]
  ),
  new Course(
    "Case #4: Dre Marie-Hélène Geoffroy (English)",
    "Beyond The Night, Jessica’s Insomnia Journey",
    "Watch Jessica’s Story",
    "https://vimeo.com/877568787/8d78e4bde4",
    "Beyond The Night",
    "https://player.vimeo.com/progressive_redirect/download/877568787/rendition/1080p/jessica%27s_story_english_-_master%20%281080p%29.mp4?loc=external&signature=7af8bca3309e1ce0ae93a694753fb5f26039f54a38bb14adb2cabb36eef60978",
    [
      new Tab(
        "Segment #1: Disease and Case Overview",
        "https://vimeo.com/874092445/3b63607b57",
        "https://player.vimeo.com/progressive_redirect/download/874092445/rendition/1080p/geoffroy_segment_1_english_masters%20%281080p%29.mp4?loc=external&signature=96a27818fd79d18401ac7f0a48a21ac84e1b510e884f62588abf6fedf92e4afa"
      ),
      new Tab(
        "Segment #2:  Initial Treatment Decision",
        "https://vimeo.com/874093053/993073f617",
        "https://player.vimeo.com/progressive_redirect/download/874093053/rendition/1080p/geoffroy_segment_2_english_masters%20%281080p%29.mp4?loc=external&signature=640967f27de42b18e21b0bb2d1291468b945511ce4793eb2b4c09543188f1ed7"
      ),
      new Tab(
        "Segment #3:  Persistent Insomnia",
        "https://vimeo.com/874093598/8431bd5bb4",
        "https://player.vimeo.com/progressive_redirect/download/874093598/rendition/1080p/geoffroy_segment_3_english_masters%20%281080p%29.mp4?loc=external&signature=dacb5c6e3fd67fa6b393b70c337e6673062f4cd9b46c79d097349ca8132cdd91"
      ),
      new Tab(
        "Segment #4: Case Summary",
        "https://vimeo.com/874094210/1f4a268818",
        "https://player.vimeo.com/progressive_redirect/download/874094210/rendition/1080p/geoffroy_segment_4_english_masters%20%281080p%29.mp4?loc=external&signature=aab162dc77e58f0ccb4f75a755992f2a8bd1807fe08be6c596450672b4a1aadd"
      ),
    ]
  ),
  new Course(
    "Case #5: Dre Marie-Hélène Geoffroy (French)",
    "Au-delà de la nuit, le voyage d'insomnie de Jessica",
    "Watch L’histoire de Jessica",
    "https://vimeo.com/877568698/da79f82e22",
    "Au Dela de la Nuit",
    "https://player.vimeo.com/progressive_redirect/download/877568698/rendition/1080p/jessica%27s_story_french_master%20%281080p%29.mp4?loc=external&signature=8360de5405089cf3bbb063779f0538e5b13888ea3bb1a00ce352a6dbb868d7f4",
    [
      new Tab(
        "Segment #1: Introduction et aperçu du cas",
        "https://vimeo.com/873749133/45e4e582e3",
        "https://player.vimeo.com/progressive_redirect/download/873749133/rendition/1080p/geoffroy_segment_1_french_masters%20%281080p%29.mp4?loc=external&signature=761834060ad447ec644d69da96a41fd7ab6d722bb012b1a83d0a4a1d6a53af5b"
      ),
      new Tab(
        "Segment #2:  Décisions de traitement initiales",
        "https://vimeo.com/873749731/ecc08b5f8c",
        "https://player.vimeo.com/progressive_redirect/download/873749731/rendition/1080p/geoffroy_segment_2_french_masters%20%281080p%29.mp4?loc=external&signature=aeba582ef4437ee086fcea9f27f484b8ff11b07db4f6d67e1c781c2bf152bf49"
      ),
      new Tab(
        "Segment #3:  Insomnie persistante!",
        "https://vimeo.com/873750339/7dd707201c",
        "https://player.vimeo.com/progressive_redirect/download/873750339/rendition/1080p/geoffroy_segment_3_french_masters%20%281080p%29.mp4?loc=external&signature=c6de0bfdaa71f1568eb2d34c946238f31bb973de133d148bd1e480e028eef03c"
      ),
      new Tab(
        "Segment #4: Conclusion du cas",
        "https://vimeo.com/873750781/21620ebfe4",
        "https://player.vimeo.com/progressive_redirect/download/873750781/rendition/1080p/geoffroy_segment_4_french_masters%20%281080p%29.mp4?loc=external&signature=de3afb175b5f39273c8cc259d6c354d2d6e59358d7f36c7fafe51deec0c5ec47"
      ),
    ]
  ),
];

class Case {
  constructor(cas, dr, report) {
    this.case = cas;
    this.dr = dr;
    this.report = report;
  }
}
const cases = [
  new Case(
    "",
    "Case #1: Dr Noel Amaladoss",
    "Optimizing Treatment Outcomes in Insomnia"
  ),
  new Case(
    "",
    "Case #2: Dr Daniel Ngui",
    "Multimorbidity, Polypharmacy and the Stressors of Life"
  ),
  new Case(
    "",
    "Case #3: Dre Sarah Landry",
    "Vignette Clinique - Madame Patricia"
  ),
  new Case(
    "",
    "Case #4: Dre Marie-Hélène Geoffroy (English)",
    "Beyond The Night, Jessica’s Insomnia Journey"
  ),
  new Case(
    "",
    "Case #5: Dre Marie-Hélène Geoffroy (French)",
    "Au-delà de la nuit, le voyage d'insomnie de Jessica"
  ),
];

class Prov {
  constructor(name, value) {
    this.name = name;
    this.value = value;
  }
}
const prov = [
  new Prov("Alberta", "alberta"),
  new Prov("British Columbia", "britishColumbia"),
  new Prov("Manitoba", "manitoba"),
  new Prov("New Brunswick", "newBrunswick"),
  new Prov("Newfoundland and Labrador", "newfoundlAndLabrador"),
  new Prov("Northwest Territories", "northwestTerritories"),
  new Prov("Nova Scotia", "novaScotia"),
  new Prov("Nunavut", "Nunavut"),
  new Prov("Ontario", "Ontario"),
  new Prov("Prince Edward Island", "princeEdwardIsland"),
  new Prov("Quebec", "Quebec"),
  new Prov("Saskatchewan", "Saskatchewan"),
  new Prov("Yukon", "Yukon"),
];

class Pract {
  constructor(name, value) {
    this.name = name;
    this.value = value;
  }
}
const pract = [
  new Pract("Doctor", "doctor"),
  new Pract("Pharmacist", "pharmacist"),
  new Pract("Nurse", "nurse"),
];

class ArticulateCourses {
  constructor(link) {
    this.link = link
  }
}
const articulateCourses = [
  new ArticulateCourses("https://minds-courses112.web.app/"),
  new ArticulateCourses("https://minds-courses111.web.app/"),
  new ArticulateCourses("https://minds-courses113.web.app/"),
  new ArticulateCourses("https://minds-courses114.web.app/"),
  new ArticulateCourses("https://minds-courses115.web.app")
]

const licMask = {
  doctor: {
    alberta: [6],
    britishColumbia: [5],
    manitoba: [5],
    newBrunswick: [5, 7],
    newfoundlAndLabrador: [5],
    novaScotia: [6],
    Ontario: [5, 6],
    princeEdwardIsland: [3, 4],
    Quebec: [5],
    Saskatchewan: [6],
  },
  pharmacist: {
    alberta: [4, 5],
    britishColumbia: [5],
    manitoba: [5],
    newBrunswick: [4],
    newfoundlAndLabrador: [5, 6],
    northwestTerritories: [5, 9],
    novaScotia: [3, 4, 5],
    Ontario: [6],
    Quebec: [6],
    Saskatchewan: [4, 5],
    Yukon: [9, 8],
  },
  nurse: {
    alberta: [5, 6],
    britishColumbia: [5, 7],
    manitoba: [6],
    newBrunswick: [6],
    newfoundlAndLabrador: [5],
    northwestTerritories: [4, 5, 9],
    novaScotia: [5, 6],
    Nunavut: [3, 4],
    Ontario: [7, 8],
    princeEdwardIsland: [6],
    Quebec: [6, 7],
    Saskatchewan: [7],
  },
};

export default courses;
export { prov, pract, licMask, cases, articulateCourses };
