<template>
  <div class="bar">
    <div>
      <img
        src="../assets/photos/logo.png"
        alt="image is not available"
        @click="goHome"
        class="log-home"
      />
    </div>
    <p>{{ error }}</p>
    <div v-if="user">
      <Avatar icon="pi pi-user" shape="circle" />
      <Dropdown
        v-model="options"
        :options="userOptions"
        optionLabel="name"
        optionValue="name"
        :placeholder="user.displayName"
        @change="choose"
      >
      </Dropdown>
    </div>
    <div v-else>
      <Button
        label="Info"
        class="p-button-outlined p-button-info"
        @click="register"
        >Register</Button
      >
      <Button
        label="Info"
        class="p-button-outlined p-button-info"
        @click="login"
        >Login</Button
      >
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { auth } from "../firebase/config";
import { useRouter } from "vue-router";
import useLogout from "../modules/useLogout";
export default {
  props: ["changes"],
  setup(props, context) {
    const router = useRouter();
    const error = ref(null);
    const isReg = ref(true);
    const user = ref(null);
    const options = ref(null);
    const PermitAnalytics = ref(process.env.VUE_APP_PERMIT.split(","));
    const permited = ref(false);
    const userOptions = ref([null]);
    if (props.changes) {
      userOptions.value = props.changes;
    }
    const choose = async () => {
      if (options.value === "Logout") {
        const { logoutError } = await useLogout();
        if (logoutError.value) {
          error.value = logoutError.value;
        } else {
          router.push({ name: "Home" });
        }
      } else if (options.value === "Case List") {
        router.push({ name: "main" });
      } else if (options.value === "My Profile") {
        router.push({
          name: "myProfile",
          params: { id: auth.currentUser.uid },
        });
      } else if (options.value === "Analytics") {
        window.location.href = "https://analytics11.web.app/";
      }
    };
    auth.onAuthStateChanged((_user) => {
      user.value = _user;
      if (_user) {
        permited.value = PermitAnalytics.value.includes(_user.uid);
        if (permited.value) {
          userOptions.value.unshift({ name: "Analytics" });
        }
      }
    });
    const login = () => {
      isReg.value = true;
      context.emit("isReg", isReg.value);
    };
    const register = () => {
      isReg.value = false;
      context.emit("isReg", isReg.value);
    };
    const goHome = () => {
      router.push("/");
    };
    return {
      login,
      register,
      user,
      userOptions,
      options,
      choose,
      error,
      goHome,
      PermitAnalytics,
    };
  },
};
</script>

<style scoped>
.log-home {
  width: 200px;
  margin: 10px;
}
.log-home:hover {
  cursor: pointer;
}
.bar {
  background-color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.p-button-outlined {
  margin: 0 10px;
  padding: 5px 10px;
}
.p-dropdown {
  border: transparent solid 0px;
  margin: 0 25px 0 0;
}
.p-dropdown:hover {
  border: transparent solid 0px;
}
.p-dropdown:focus {
  border: transparent solid 0px;
  box-shadow: none;
}
.p-avatar {
  margin: 0 10px;
}
@media (max-width: 750px) {
  .log-home {
    width: 150px;
  }
}
@media (max-width: 450px) {
  .log-home {
    width: 100px;
  }
}
@media (max-width: 350px) {
  .p-button-outlined {
    margin: 1px;
  }
}
</style>
