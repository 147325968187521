import { ref } from "vue";
import { db } from "../firebase/config";
import { doc, getDoc, setDoc } from "firebase/firestore";

const checkPeople = async (email) => {
  const checkPeopleError = ref(null);
  const document = ref({});
  try {
    const res = await getDoc(doc(db, "sleep", email));
    if (!res.exists()) {
      const res2 = await getDoc(doc(db, "onco", email));
      const res3 = await getDoc(doc(db, "rheum", email));
      if (res2.exists()) {
        document.value = res2.data();
        document.value.course = { 0: [], 1: [], 2: [], 3: [], 4: [] };
        document.value.visited = 0;
        await setDoc(doc(db, "sleep", email), document.value);
      } else if (res3.exists()) {
        document.value = res3.data();
        document.value.course = { 0: [], 1: [], 2: [], 3: [], 4: [] };
        document.value.visited = 0;
        await setDoc(doc(db, "sleep", email), document.value);
      }
    }
  } catch (err) {
    checkPeopleError.value = err.message;
  }
  return { checkPeopleError };
};
export default checkPeople;
