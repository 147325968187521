<template>
  <img src="../assets/photos/logo.png" alt="image is not available" />
  <p class="danger">{{ loginError }}</p>
  <form>
    <input
      type="text"
      placeholder="Email"
      v-model="email"
      ref="emailRef"
      v-on:keydown.enter="login"
      required
    />
    <input
      type="password"
      placeholder="Password"
      v-model="password"
      ref="passwordRef"
      v-on:keydown.enter="login"
      required
    />
    <Button
      v-if="!resetToggle"
      label="Info"
      class="p-button-outlined p-button-info"
      @click="login"
      >Login Now</Button
    >
    <Button
      v-else
      label="Info"
      class="p-button-outlined p-button-info"
      @click="sendEmail"
      >Send Email</Button
    >
    <div>
      <p v-if="!resetToggle" @click="changePassword" class="forget">
        Forget Password
      </p>
      <p v-else @click="changePassword" class="forget">Return back</p>
    </div>
  </form>
</template>

<script>
import { ref } from "vue";
import useLogin from "../modules/useLogin";
import updatePassword from "../modules/updatePassword";
import { useRouter } from "vue-router";
import checkPeople from "../modules/checkPeople";
export default {
  setup() {
    const router = useRouter();
    const logo = ref(null);
    const loginError = ref(null);
    const email = ref(null);
    const password = ref(null);
    const passwordRef = ref(null);
    const emailRef = ref(null);
    const resetToggle = ref(false);
    const login = async () => {
      const emailLower = ref(null);
      emailLower.value = email.value.toLowerCase();
      loginError.value = null;
      const { error } = await useLogin(emailLower.value, password.value);
      if (error.value) {
        loginError.value = error.value;
        emailRef.value.style = "border-bottom: 2px solid var(--danger)";
        passwordRef.value.style = "border-bottom: 2px solid var(--danger)";
      } else {
        const { checkPeopleError } = await checkPeople(emailLower.value);
        if (checkPeopleError.value) {
          console.log(checkPeopleError.value);
        } else {
          router.push({ name: "main" });
        }
      }
    };
    const changePassword = () => {
      if (!resetToggle.value) {
        passwordRef.value.style = "display: none";
        resetToggle.value = true;
      } else {
        passwordRef.value.style = "display: block";
        resetToggle.value = false;
      }
    };
    const sendEmail = async () => {
      if (email.value) {
        const { updatePasswordError } = await updatePassword(email.value);
        passwordRef.value.style = "display: block";
        resetToggle.value = false;
        if (updatePasswordError) {
          loginError.value = updatePasswordError.value;
        }
      } else {
        loginError.value = "please Enter Valid Email";
      }
    };
    return {
      logo,
      loginError,
      email,
      password,
      passwordRef,
      emailRef,
      login,
      changePassword,
      resetToggle,
      sendEmail,
    };
  },
};
</script>

<style scoped>
form {
  max-width: 550px;
}
img {
  display: block;
  margin: 20px auto;
  width: 200px;
  height: auto;
}
.card {
  margin: 200px auto;
}
.switch {
  display: flex;
  align-items: center;
  justify-content: left;
}
.toggle {
  margin: 0 10px 0 0;
}
.forget {
  display: inline-block;
}
.forget:hover {
  cursor: pointer;
  color: var(--safe);
}
@media (max-width: 650px) {
  form {
    margin: 50px 20px;
  }
}
</style>
