<template>
  <router-view />
</template>
<style>
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Archivo+Narrow:ital,wght@1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Chivo:wght@700&display=swap");
:root {
  --ordinary: rgb(156, 156, 146);
  --safe: rgb(123, 180, 207);
  --danger: rgb(235, 67, 67);
  --head: rgb(27, 19, 99);
  --card: rgb(175, 201, 248);
}
* {
  font-family: "Fira Sans", sans-serif;
}
html,
body {
  margin: 0;
  background-color: azure;
}
a {
  color: #2c3e50;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
form {
  margin: 0 auto;
  margin-top: 30px;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 5px 5px 10px rgba(2, 2, 2, 0.05);
  border: 1px solid var(--ordinary);
  background: white;
}
input {
  border: 0;
  border-bottom: 1px solid var(--ordinary);
  padding: 10px;
  outline: none;
  display: block;
  width: 100%;
  box-sizing: border-box;
  margin: 20px auto;
  font-size: 16px;
}
input:focus {
  border-bottom: 2px solid var(--safe);
  width: 97%;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.headG {
  margin: 0;
  height: 250px;
  background-color: var(--head);
}
.titleG {
  font-family: "Chivo", sans-serif;
  font-size: 2.5rem;
  color: azure;
  text-align: left;
  margin: 0;
  padding: 30px 0 10px 30px;
}
.subtitleG {
  font-family: "Archivo Narrow", sans-serif;
  font-size: 1.2rem;
  color: rgb(194, 209, 209);
  text-align: left;
  margin: 0;
  padding: 10px 0 0 30px;
}
.cardG {
  position: relative;
  bottom: 50px;
  display: inline-block;
  padding: 10px;
  /* min-height: 250px; */
  width: 80%;
  background-color: var(--card);
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgb(27, 19, 99);
}
.atFoot {
  text-align: left;
  font-size: 0.8rem;
}
.terms {
  cursor: pointer;
  text-decoration: underline;
}
.terms:hover {
  color: var(--safe);
}
.danger {
  color: var(--danger);
}
</style>
